import axios from "axios";
import { urlBase,urlExternal,URIOAuth } from "../data/config";


async function OAuth(username,password){

    let url = urlBase
    const switchCode = window.localStorage.getItem('urlPenca');

    if(switchCode === '002' || switchCode === '005'){
        url = urlExternal;
    }   
    url = (url + URIOAuth).replace(':code', switchCode);
    


    const LoginUser={
        "username":username,
        "password":password,
        "grant_type":"password",
        "client_id":"penca-solcre",
        "oauth_type":"1"
    }

    const response = await axios.post(url, LoginUser);
    

    return response;
        
    
}

export {OAuth}