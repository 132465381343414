import React,{useContext, useState, useEffect} from 'react';
import { PencaContext } from '../context/pencaContext';
import { TokenContext } from '../context/tokenContext';
import { PaginationContext } from "../context/paginationContext";
import { getUser } from '../services/getUsers';
import { getFile } from '../services/getFile';
import { Table } from './Table';
import { userLoading } from '../data/userLoading';
import { Pagination } from './Pagination';




const Home = () =>{


    const {setToken} = useContext(TokenContext)

    const {setUrlNum} = useContext(PencaContext)

    const [download,setDownload] = useState('Descargar')

    const [isActive,setActive]= useState(false)

    const [errordownload,setErrordownload] = useState('')
    // Table
    const [users,setUsers] = useState(userLoading)

    const [pages,setPages] = useState()

    const [totalUsers,setTotalUsers] = useState(0)

    const [pageValueURL,setPageValueURL] = useState(1)

    useEffect(()=>{
        

        const usersData = async ()=>{
            setUsers(userLoading)
            const data = await getUser(pageValueURL)
            
            if(data.status !== 200 ){
                setToken(null)
                localStorage.removeItem('token')
            }else{
                setTotalUsers(data.data.total_items)
                setPages(data.data.page_count)
                setUsers(data.data._embedded.users)
            }
        }

        


        
        usersData()
        
      }, [pageValueURL, setToken])
  
      
    // End Table
    const handleSingOut = async ()=>{
        
        setToken(null)
        localStorage.removeItem('token')

        setUrlNum("")
        localStorage.removeItem('urlPenca')
    }

    const downloadFile = async ()=>{

        setActive(true)

        setDownload('Descargando...')

        
        const file = await getFile()
        setDownload('Descargar')
        setActive(false)
        if(file !== true){
            setErrordownload('Error en la descarga,por favor intente nuevamente')
            setTimeout(() => {
                setErrordownload('')
                setToken(null)
                localStorage.removeItem('token')
            }, 2000);
        }       
            
        
    }

    return(
        <div className='main'>
            <div className="container">
                    
                    <img className='logo-home' src="Assets/logo-solcre.svg" alt="Logo" />
                <div>
                    <button className="button-session" onClick={handleSingOut}>Cerrar sesi&oacute;n</button>
                </div>
            </div>
            <div className="button-container">
                    <button onClick={downloadFile} className="button">
                        <div className={isActive ? "none" : 'icon'}>
                            <img  src="./Assets/download.svg" alt='download' />
                        </div>
                        <span>{download}</span>
                    </button>
            </div>
            <p className='errordownload'>{errordownload}</p>
            <div className='TableUsers'>
                <Table users={users}/>
                <PaginationContext.Provider value={{pageValueURL,setPageValueURL}}>
                    <Pagination page_size={pages} total_items={totalUsers}/>
                </PaginationContext.Provider>
            </div>
        </div>
    );
};

export {Home}