import React,{useState} from 'react';
import { Route,Routes,BrowserRouter,Navigate } from 'react-router-dom';
import PrivateRoutes from './router/PrivateRoutes';
import PublicRoutes from './router/PublicRoutes';
import { PencaContext } from './context/pencaContext';
import { TokenContext } from './context/tokenContext';







function App() {

  const [token,setToken] = useState(window.localStorage.getItem('token'))
  const [urlNum,setUrlNum]= useState(window.localStorage.getItem('urlPenca'))



  return (
      <TokenContext.Provider value={{token,setToken}}>
        <PencaContext.Provider value={{urlNum,setUrlNum}}>
          <BrowserRouter>
            <Routes>
              {
                (token)
                
                  ? <Route path="/*" element={<PrivateRoutes />} />
                  : <Route path="/*" element={<PublicRoutes />} />
              }
              <Route path='/*' element={<Navigate to='/login' />} />
            </Routes>
        </BrowserRouter>
        </PencaContext.Provider>
      </TokenContext.Provider>
  );
};

export default App;
