import React, {useState,useContext}  from 'react';
import { PencaContext } from '../context/pencaContext';
import { TokenContext } from '../context/tokenContext';
import { OAuth } from '../services/OAuth';
import { Usuarios } from '../data/pencas';


const Login = () =>{
    const {setToken} = useContext(TokenContext)

    const {setUrlNum} = useContext(PencaContext)

    const [username,setUsername] = useState('')
    const [password,setPassword] = useState('')

    const [login,setLogin] = useState('Iniciar sesión')    
    

    const [errormsj,setErrormsj] = useState('')


    const handleLogin = async (event)=>{
        event.preventDefault()
        try {
            const userinput = username.split('@')[1]
            
            if(Usuarios[userinput]){
                const URLnum = Usuarios[userinput]

                localStorage.setItem('urlPenca', URLnum)
                setUrlNum(URLnum)

                setLogin('Iniciando...')
                const user = await OAuth(username,password)
                const userToken = user.data.access_token
                localStorage.setItem('token', userToken)
                setToken(userToken)
                setUsername('')
                setPassword('')
            }else{
                setErrormsj('Cliente no válido') 
                setLogin('Iniciar sesión')
            }
        } catch (e) {
           if(e.request.status === 401 || e.request.status === 400 ){
            setErrormsj('Usuario o contraseña incorrectos') 
            setLogin('Iniciar sesión')          
           }
        }
    }

    return(
        <div className="login_container"> 
            <div className="login_container_left">
                <img src="Assets/logo-solcre.svg " alt="Logo" /> 
            </div>

            <div className="login_container_right">
                <form onSubmit={handleLogin} id='form'>
                    <h1> Login </h1>
                    <input 
                        name="Username"
                        value={username}
                        onChange={(e)=> setUsername(e.target.value)}
                        type="email"
                        className="login-container-input"
                        placeholder="Usuario@solcre.com" 
                    /> 
                    <input 
                        name="Password"
                        value={password}
                        onChange={(e)=> setPassword(e.target.value)}
                        type="password" 
                        className="login-container-input" 
                        placeholder="Password" 
                    />
                    <p className='errorLogin'>{errormsj}</p>
                    <button className="button-session">{login}</button>
                </form>
            </div>
        </div>
    );
};

export {Login}
