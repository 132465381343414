import React from "react";

import { Row } from "./Row";





export const Table = ({users}) => {

  
    
  return (  
      <table className="table-container">
        <thead>
          <tr id="thead">
            <td id="data">Id</td>
            <td id="data">Username</td>
            <td id="data">Documento</td>
            <td id="data">Nombre</td>
            <td id="data">Apellido</td>
            <td id="data">Fecha de nacimiento</td>
            <td id="data">Puntos</td>
          </tr>
        </thead>
        <tbody > 
          {
              users.map((user)=>{
                const date=user.dateOfBirthday?user.dateOfBirthday.date.split(' ')[0]:''
                return(
                  
                  <Row key={user.id} username={user.email} id={user.id} document={user.document} name={user.name} last_name={user.lastName} date_of_birthday={date} points={user.points}/>    
                  
                  
                )
              })
              

          }
        
        </tbody>
      </table>
  );
};