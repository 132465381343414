import React, { useContext } from 'react';
import { useState } from 'react';
import { PaginationContext } from '../context/paginationContext';



export const Pagination = ({page_size,total_items})=>{

    const [pageValue,setPageValue] = useState(1)

    const {setPageValueURL} = useContext(PaginationContext)
    
    const next = ()=>{
   
        if(pageValue-page_size!==0){
            const page =+pageValue+1
            setPageValue(page)
            setPageValueURL(page)
        }else{
            setPageValue(page_size)
            setPageValueURL(page_size)
        }
    }

    const prev = ()=>{
        if(pageValue>1){
            
            setPageValue(pageValue-1)
            setPageValueURL(pageValue-1)
        }else{
            setPageValue(1)
        }
    }

    return(
        
        <div className='container-pagination'>
            <button onClick={prev}  className='prev-container' id='prev'><img className='img-button' src="./Assets/arrow_back_ios_FILL0_wght400_GRAD0_opsz48.svg" alt='' /></button>
                    <div className='pagination'>
                        <select className='selectPage'  value={pageValue} onChange={(e)=>{
                            setPageValue(e.target.value)
                            setPageValueURL(e.target.value)
                            }} >
                        {
                            Array(page_size).fill(0).map((val, pageValue) =>{ 
                            return <option key={pageValue}> {pageValue+1}</option>   
                        })
                        }
                        </select>                          
                    </div>
            <button onClick={next} type="button" className='prev-container' id='next'><img className='img-button' src="./Assets/arrow_forward_ios_FILL0_wght400_GRAD0_opsz48.svg" alt=''  /></button>
            <div className='total-container'>
                <p className='totalUsers'>Usuarios Totales:{total_items}</p>
            </div>
        </div>            


            
    );
};