import React from 'react';



export const Row = ({ username,id,document,name,last_name,date_of_birthday,points})=>{

    return(
            <tr>
                <td>{id}</td>
                <td>{username}</td>
                <td>{document}</td>
                <td>{name}</td>
                <td>{last_name}</td>
                <td>{date_of_birthday}</td>  
                <td>{points}</td>
            </tr>
            
    );
};