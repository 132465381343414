import axios from "axios";
import { urlBase, urlExternal, URIUsers } from '../data/config';


async function getUser(page){
    try {
           let url = urlBase;
           const switchCode = window.localStorage.getItem('urlPenca');

           if (switchCode === '002' || switchCode === '005') {
             url = urlExternal;
           }

           url = (url + URIUsers).replace(':code', switchCode).replace(':page', page);
    
    
        // const URLswitch = window.localStorage.getItem('urlPenca');
        const token = window.localStorage.getItem('token')
    
    
        // const URL = URLUsers.replace(':code', URLswitch).replace(':page',page )
    
        
    
        const response = await axios({
          url: url,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
    
    
    
        return response

    } catch(e) {
        return e
    }


}

export {getUser}
