import {
    Navigate,
    Route, Routes
  } from 'react-router-dom';

  import { Home } from '../components/Home';
  
  
  
  function PrivateRoute() {
    return (
      <Routes>
        <Route path='/home' element={<Home/>} />
  
        <Route path="/*" element={<Navigate to="/home" />} />
      </Routes>
    )
  }
  
  export default PrivateRoute