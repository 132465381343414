import {
    Navigate,
    Route, Routes
  } from 'react-router-dom';
  import { Login } from '../components/Login';
  
  
  
  function PublicRoute() {
    return (
      <Routes>
        <Route path='/login' element={<Login />} />
  
        <Route path='/*' element={<Navigate to="/login"/>} />
      </Routes>
    )
  }
  
  export default PublicRoute