import axios from 'axios';
import FileSaver from 'file-saver';
import { URLFile } from '../data/config';

async function getFile() {
  const switchCode = window.localStorage.getItem('urlPenca');
  const token = window.localStorage.getItem('token');


  let url = URLFile;
  url = url
    .replace(':code', switchCode)
    .replace(':id', switchCode)
    .replace(':token', token);

  try {
    // Attempt to fetch the file
    const response = await axios({
      url: url,
      method: 'GET',
    });

    // Convert response to a Blob and save using FileSaver
    const dataAsBlob = new Blob([response.data], {
      type: 'text/csv;charset=utf-8',
    });
    FileSaver.saveAs(dataAsBlob, 'Penca.csv');
    return true;
  } catch (e) {
    // Log and return error
    console.error('Error downloading file:', e);
    return false;
  }
}

export { getFile };

